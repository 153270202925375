import optionData from '@/assets/js/optionData';
import { fetchClickCountPage } from '@/api/DDJ/v3/clickCount';
import { api } from '@/api/DDJ/v3/clickCount';
import { fetchCountryList } from '@/api/DDJ/v3/country';
import { getAdvertiserList } from '@/api/DDJ/v3/advertiser/index';
import qs from 'querystring';
import dateUtils from '@/utils/dateutils.js';
import { myMixin } from '@/mixins/mixins.js';
import { mapActions, mapState } from 'vuex';
import GroupSelect from '@/components/GroupSelect';
import { getPermissionAllList } from '@/api/account/permission';
export default {
  mixins: [myMixin],
  components: {
    GroupSelect,
  },
  data() {
    return {
      filter: {
        date: [],
        //这个就是pm
        pms: [],
        sourceIds: [],
      },
      options: {
        country: [],
        os: optionData.osOption,
        advertiser: [],
        manager: [],
      },
      list: [
        {
          offerId: '1',
        },
      ],
      loading: {
        list: false,
      },
      pages: {
        pageNum: 1,
        pageSize: 20,
      },
      pagesTotal: 0,
      isShowPage: false,
      rules: {},
    };
  },
  mounted() {
    //获取Source
    this.getSourceIdsList();
    //pm
    this.managerList();
    //日期默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.filter.date = [];
    this.filter.date[0] = dateUtils.date2StrYMD(end);
    this.filter.date[1] = dateUtils.date2StrYMD(start);
    this.getCountry();
    this.getAdvertiser();
    this.getList();
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // 列表
    getList(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      console.log('filter date:', this.filter.date);
      this.filter.fromDate = this.filter.date[0];
      this.filter.toDate = this.filter.date[1];
      this.filter.pmList = this.filter.pms.toString();
      this.filter.sourceIdList = this.filter.sourceIds.toString();
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      console.log(query);
      fetchClickCountPage(query).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    // 导出
    downloadReport() {
      let strParam = qs.stringify(this.filter);
      window.open(`${api.CLICK_COUNT_DOWNLOAD}?${strParam}`, '_blank');
    },
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    // 上游
    getAdvertiser() {
      getAdvertiserList().then((res) => {
        this.options.advertiser = res.result;
        console.log(res);
      });
    },

    managerList() {
      getPermissionAllList().then((res) => {
        this.options.manager = res.result;
      });
    },

    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
